
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';
import { ItemService } from '@/services/item-service';
import DefaultLayout from '@/components/DefaultLayout.vue';
import { CustomerService } from '@/services/customer-service';
import { InvoiceService } from '@/services/invoice-service';
import J1lImg from '@/components/J1lImg.vue';
import AuthService from '@/services/auth-service';

@Component({
  components: { DefaultLayout, J1lImg }
})
export default class Dashboard extends Vue {
  $refs!: {
    defaultActions: HTMLFormElement;
  };
  /* Properties */
  /* Store Actions */
  /* Watchers */
  /* Data */
  isConfirmed: boolean = false;
  isGettingConfirmedStatus: boolean = false;
  customerMetrics: any = null;
  /* Utility Functions */
  async getCustomerMetrics() {
    const response = await CustomerService.GetCustomerSummaryMetrics();
    this.customerMetrics = response.data;
  }
  goToOrders() {
    this.$router.push('Orders');
  }
  /* Computed */
  get displayName() {
    return this.$refs.defaultActions.getDisplayName();
  }
  /* Loaders */
  /* Mounted */
  async mounted() {
    this.isGettingConfirmedStatus = true;
    const isConfirmed = await AuthService.isConfirmed();
    if (isConfirmed.data) {
      this.isConfirmed = true;
      this.getCustomerMetrics();
    }
    this.isGettingConfirmedStatus = false;
  }
  /* Created */
  name: string = 'dashboard';
}
